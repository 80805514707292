var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box relative flex relative",class:{ 'flex-col': _vm.mobile }},[_c('loader',{attrs:{"loading":_vm.loading}}),_c('div',{staticClass:"h-full flex flex-col justify-start items-center border-r border-font-gray-15",class:{ 'w-full': _vm.mobile, 'w-1/3': !_vm.mobile }},[_c('span',{staticClass:"text-font-gray font-semibold text-xs mr-auto"},[_vm._v("Smarp points")]),(_vm.userScore)?_c('div',{staticClass:"flex-1 flex justify-center items-center w-full",class:{ 'flex-row pt-2': _vm.mobile, 'flex-col': !_vm.mobile }},[(_vm.userScore.user_image)?_c('div',{staticClass:"h-10 w-10 rounded-full mb-2",style:('background-image: url(' +
          _vm.userScore.user_image +
          '); background-repeat: no-repeat; background-size: cover; background-position: center;')}):_c('div',{staticClass:"h-10 w-10 rounded-full mb-2 bg-white"}),_c('span',{staticClass:"text-xs",class:{ 'mx-4': _vm.mobile }},[_vm._v(_vm._s(_vm.userScore.user_score)+" "),_c('i',{staticClass:"mdi mdi-star-outline -ml-1",staticStyle:{"color":"#ffc022"}})]),(_vm.userScore.user_score_diff != '-')?_c('span',{staticClass:"text-xs mt-1",class:{
          'text-success': _vm.userScore.user_score_diff > 0,
          'text-danger': _vm.userScore.user_score_diff < 0,
        }},[(_vm.userScore.user_score_diff > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm._f("numberFormat")(_vm.userScore.user_score_diff,0)))]):_c('span',{staticClass:"text-xs mt-1 text-white"},[_vm._v(_vm._s(_vm.userScore.user_score_diff))])]):_vm._e()]),_c('div',{staticClass:"h-full flex flex-col justify-start items-center",class:{ 'px-0 w-full': _vm.mobile, 'px-2 w-2/3': !_vm.mobile }},[_vm._m(0),(_vm.topScore)?_c('div',{staticClass:"flex-1 min-h-0 w-full flex",class:{ 'flex-row': _vm.mobile, 'flex-col': !_vm.mobile }},_vm._l((_vm.topScore),function(el,index){return _c('div',{key:index,staticClass:"w-full flex justify-start items-center",class:{
          'h-20 flex-col overflow-hidden': _vm.mobile,
          'flex-row h-full': !_vm.mobile,
        }},[(el?.image_url)?_c('div',{staticClass:"h-6 w-6 shadow-md rounded-full bg-white mr-1 relative",class:{ 'mt-2': _vm.mobile },style:('background-image: url(' +
            el?.image_url +
            '); background-repeat: no-repeat; background-size: cover; background-position: center;')}):_vm._e(),_c('span',{staticClass:"text-xxs capitalize",class:{ 'text-center mt-2': _vm.mobile, 'truncate mr-auto': !_vm.mobile }},[_c('i',{staticClass:"mr-1 mdi",class:_vm.getIcon(index)}),_vm._v(_vm._s(el.name))]),_c('span',{staticClass:"text-xxs",class:{ 'mt-auto': _vm.mobile }},[_vm._v(_vm._s(el.score)),_c('i',{staticClass:"mdi mdi-star-outline",staticStyle:{"color":"#ffc022"}})])])}),0):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text-font-gray font-semibold text-xs mr-auto"},[_vm._v("Smarp leaderboard ("),_c('small',[_vm._v("this month")]),_vm._v(")")])
}]

export { render, staticRenderFns }