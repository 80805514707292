<template>
  <div class="relative">
    <!-- <div v-if="!seeit" class="absolute top-0 left-0 h-full w-full z-20 rounded-lg" style="backdrop-filter: blur(10px);"></div> -->
    <loader :loading="loading" />
    <div class="overflow-hidden p-4 flex flex-col">
      <div class="flex items-center mb-2">
        <div class="module-title">Chargeability</div>
        <div
          v-if="globalParamsPretty.rol.role_type_link !== 'EP'"
          class="ml-auto flex"
        >
          <router-link :to="{ name: 'ChargeabilitySls' }" class="header-btn">
            <i class="mdi mdi-view-split-vertical"></i>
          </router-link>
        </div>
      </div>
      <div class="box h-full flex flex-col">
        <div class="box-title">
          <span class="text-white">YTD</span> & Y-1 &
          <span class="text-plan">PLAN</span>
        </div>
        <div
          class="flex-1 overflow-hidden text-lg flex flex-col justify-around"
        >
          <div class="flex items-center">
            <div class="flex-1 overflow-hidden pr-4">
              <div class="bg-progress h-3 rounded-full overflow-hidden">
                <div
                  class="h-full bg-white rounded-full"
                  :style="
                    'width: ' + ytd_chargeability + '%; transition: all .3s'
                  "
                ></div>
              </div>
            </div>
            <div class="flex-none w-20 text-right text-xl font-semibold">
              {{ ytd_chargeability | numberFormat(2) }}%
            </div>
          </div>

          <div class="flex items-center">
            <div class="flex-1 overflow-hidden pr-4">
              <div class="bg-progress h-3 rounded-full overflow-hidden">
                <div
                  class="h-full bg-progress-bar_gray rounded-full"
                  :style="
                    'width:' + ytd_1_chargeability + '%; transition: all .3s'
                  "
                ></div>
              </div>
            </div>
            <div
              class="flex-none w-20 text-right text-xl font-semibold text-font-gray"
            >
              {{ ytd_1_chargeability | numberFormat(2) }}%
            </div>
          </div>

          <div class="flex items-center">
            <div class="flex-1 overflow-hidden pr-4">
              <div class="bg-progress h-3 rounded-full overflow-hidden">
                <div
                  class="h-full bg-plan rounded-full"
                  :style="'width:' + plan + '%; transition: all .3s'"
                ></div>
              </div>
            </div>
            <div
              class="flex-none w-20 text-right text-xl font-semibold text-plan"
            >
              {{ plan | numberFormat(2) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { state } from "@/store";

export default {
  data() {
    return {
      loading: false,
      ytd_1_chargeability: 0,
      ytd_chargeability: 0,
      plan: 0,
    };
  },
  watch: {
    params() {
      this.load();
    },
  },
  methods: {
    load() {
      this.loading = true;

      // if(state.rolSelected.role_type_link != 'presidencia'){

      this.axios
        .get("chargeability/ytd", { params: this.params })
        .then((response) => {
          this.ytd_1_chargeability = response.data.data.ytd_1;
          this.ytd_chargeability = response.data.data.ytd;
          this.plan = response.data.data.plan;
        });

      // }

      this.loading = false;
    },
  },
  computed: {
    globalParamsPretty() {
      return state.globalParamsPretty;
    },
    userData() {
      return state.user;
    },
    defaultSelectedRol() {
      return _.find(this.userData.roles, {
        role_type_id: this.userData.role_default.role_type_id,
        gui_role_specific_id: this.userData.role_default.gui_role_specific_id,
      });
    },
    params() {
      let params = state.globalParams;
      if (this.$route.params.catId) {
        params = {
          ...params,
          ...{ sectorAmbiciona: this.$route.params.catId },
        };
      }
      return params;
    },
    seeit() {
      if (state.rolSelected.role_type_link != "presidencia") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>
