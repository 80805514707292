var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.screen != 3)?_c('div',{staticClass:"w-full h-full module flex py-0"},[_c('pipeline',{staticClass:"w-2/5"}),_c('separator',{attrs:{"direction":'vertical'}}),_c('div',{staticClass:"w-2/5 overflow-hidden flex flex-col"},[_c('projects-management'),_c('separator',{attrs:{"direction":'horizontal'}}),_c('div',{staticClass:"flex flex-none",class:_vm.globalParamsPretty.rol.role_type_link !== 'EP'
          ? 'h-40'
          : 'h-0 overflow-hidden'},[(
          _vm.globalParamsPretty.rol.role_type_link !== 'gcspClient' &&
          _vm.globalParamsPretty.rol.role_type_link !== 'sectorLeader'
        )?_c('Chargeability',{staticClass:"w-7/12"}):_vm._e(),_c('separator',{attrs:{"direction":'vertical'}}),_c('inter-intra',{class:_vm.globalParamsPretty.rol.role_type_link !== 'gcspClient' &&
          _vm.globalParamsPretty.rol.role_type_link !== 'sectorLeader'
            ? 'w-5/12'
            : 'w-full'})],1)],1),_c('separator',{attrs:{"direction":'vertical'}}),_c('personal-dashboard',{staticClass:"w-1/5"})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }