<template>
  <div class="box relative flex relative" :class="{ 'flex-col': mobile }">
    <loader :loading="loading" />

    <div
      class="h-full flex flex-col justify-start items-center border-r border-font-gray-15"
      :class="{ 'w-full': mobile, 'w-1/3': !mobile }"
    >
      <span class="text-font-gray font-semibold text-xs mr-auto"
        >Smarp points</span
      >

      <div
        v-if="userScore"
        class="flex-1 flex justify-center items-center w-full"
        :class="{ 'flex-row pt-2': mobile, 'flex-col': !mobile }"
      >
        <div
          v-if="userScore.user_image"
          class="h-10 w-10 rounded-full mb-2"
          :style="
            'background-image: url(' +
            userScore.user_image +
            '); background-repeat: no-repeat; background-size: cover; background-position: center;'
          "
        ></div>
        <div v-else class="h-10 w-10 rounded-full mb-2 bg-white"></div>

        <span class="text-xs" :class="{ 'mx-4': mobile }"
          >{{ userScore.user_score }}
          <i class="mdi mdi-star-outline -ml-1" style="color: #ffc022"></i
        ></span>

        <span
          v-if="userScore.user_score_diff != '-'"
          class="text-xs mt-1"
          :class="{
            'text-success': userScore.user_score_diff > 0,
            'text-danger': userScore.user_score_diff < 0,
          }"
        >
          <span v-if="userScore.user_score_diff > 0">+</span
          >{{ userScore.user_score_diff | numberFormat(0) }}</span
        >
        <span v-else class="text-xs mt-1 text-white">{{
          userScore.user_score_diff
        }}</span>
      </div>
    </div>

    <div
      class="h-full flex flex-col justify-start items-center"
      :class="{ 'px-0 w-full': mobile, 'px-2 w-2/3': !mobile }"
    >
      <span class="text-font-gray font-semibold text-xs mr-auto"
        >Smarp leaderboard (<small>this month</small>)</span
      >

      <div
        v-if="topScore"
        class="flex-1 min-h-0 w-full flex"
        :class="{ 'flex-row': mobile, 'flex-col': !mobile }"
      >
        <div
          v-for="(el, index) in topScore"
          :key="index"
          class="w-full flex justify-start items-center"
          :class="{
            'h-20 flex-col overflow-hidden': mobile,
            'flex-row h-full': !mobile,
          }"
        >
          <div
            v-if="el?.image_url"
            class="h-6 w-6 shadow-md rounded-full bg-white mr-1 relative"
            :class="{ 'mt-2': mobile }"
            :style="
              'background-image: url(' +
              el?.image_url +
              '); background-repeat: no-repeat; background-size: cover; background-position: center;'
            "
          />
          <span
            class="text-xxs capitalize"
            :class="{ 'text-center mt-2': mobile, 'truncate mr-auto': !mobile }"
            ><i class="mr-1 mdi" :class="getIcon(index)"></i>{{ el.name }}</span
          >
          <span class="text-xxs" :class="{ 'mt-auto': mobile }"
            >{{ el.score
            }}<i class="mdi mdi-star-outline" style="color: #ffc022"></i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader from "../../Globals/Loader.vue";
import { state } from "@/store";

export default {
  props: ["mobile"],
  components: {
    loader,
  },
  data() {
    return {
      loading: false,
      userScore: false,
      topScore: false,
      interval: false,
    };
  },
  methods: {
    load() {
      this.loading = true;

      this.axios
        .get(process.env.VUE_APP_SOCIAL_URL + "social/user-score")
        .then((response) => {
          this.userScore = response.data;

          this.loading = false;
        });

      this.axios
        .get(process.env.VUE_APP_SOCIAL_URL + "social/top-scorer-users")
        .then((response) => {
          this.topScore = [
            response.data[0],
            response.data[1],
            response.data[2],
          ];

          this.loading = false;
        });
    },
    getIcon(index) {
      if (index == 0) {
        return "mdi-podium-gold text-gold";
      }

      if (index == 1) {
        return "mdi-podium-silver text-silver";
      }

      if (index == 2) {
        return "mdi-podium-bronze text-bronze";
      }
    },
  },
  mounted() {
    this.load();

    this.interval = setInterval(() => {
      this.load();
    }, state.refreshPersonalDashboard);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style></style>
