<template>
  <div v-if="screen != 3" class="w-full h-full module flex py-0">
    <!-- PIPELINE -->
    <pipeline class="w-2/5" />
    <separator :direction="'vertical'" />
    <!-- PROJECTS MANAGEMENT -->
    <div class="w-2/5 overflow-hidden flex flex-col">
      <projects-management />
      <separator :direction="'horizontal'" />
      <div
        class="flex flex-none"
        :class="
          globalParamsPretty.rol.role_type_link !== 'EP'
            ? 'h-40'
            : 'h-0 overflow-hidden'
        "
      >
        <Chargeability
          v-if="
            globalParamsPretty.rol.role_type_link !== 'gcspClient' &&
            globalParamsPretty.rol.role_type_link !== 'sectorLeader'
          "
          class="w-7/12"
        />
        <separator :direction="'vertical'" />
        <inter-intra
          :class="
            globalParamsPretty.rol.role_type_link !== 'gcspClient' &&
            globalParamsPretty.rol.role_type_link !== 'sectorLeader'
              ? 'w-5/12'
              : 'w-full'
          "
        />
      </div>
    </div>
    <separator :direction="'vertical'" />
    <personal-dashboard class="w-1/5" />
  </div>
</template>

<script>
import { state } from "@/store";
import Chargeability from "@/components/Desktop/Chargeability";
import InterIntra from "@/components/Desktop/InterIntra";
import PersonalDashboard from "@/components/Desktop/PersonalDashboard";
import Pipeline from "@/components/Desktop/Pipeline";
import ProjectsManagement from "@/components/Desktop/ProjectsManagement";

export default {
  components: {
    Chargeability,
    InterIntra,
    PersonalDashboard,
    Pipeline,
    ProjectsManagement,
  },
  data() {
    return {
      limit: this.$options.filters.randomNumber(100, 900) / 10,
      salesPercent: this.$options.filters.randomNumber(15, 999) / 10,
      effectiveness: this.$options.filters.randomNumber(155, 7000) / 100,
      plan: this.$options.filters.randomNumber(155, 9999) / 100,
    };
  },
  computed: {
    globalParamsPretty() {
      return state.globalParamsPretty;
    },
    user() {
      return state.user;
    },
    salesVsLimit() {
      return this.salesPercent - this.limit;
    },
    screen() {
      return state.user.screen;
    },
    userData() {
      return state.user;
    },
    defaultSelectedRol() {
      return _.find(this.userData.roles, {
        role_type_id: this.userData.role_default.role_type_id,
        gui_role_specific_id: this.userData.role_default.gui_role_specific_id,
      });
    },
    rolSelected() {
      return state.rolSelected;
    },
    globalParams() {
      return state.globalParams;
    },
    paramsQuery() {
      return this.$route.query.seeDataAs;
    },
  },
  watch: {
    paramsQuery(n, o) {
      if (n == undefined) {
        this.$router.go().catch((failure) => {
          if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
            // show a small notification to the user
            showToast("Login in order to access the admin panel");
          }
        });
      }
    },
  },
  created() {
    if (parseInt(this.screen) === 3) {
      this.$router.push("PersonalDashboard");
    }

    if (this.paramsQuery == "true") {
      this.$router.replace({ query: null });
    }
  },
};
</script>
